.datatableTitle {
  font-size: large;
  font-weight: bold;
  color: #808080;
  font-size: 15px;
}

.list-data {
  width: 100%;
  border-collapse: collapse;
}

.listnih,
.listnoh {
  border: 2px solid #ddd;
  padding: 5px;
  text-align: center;
}

.listnih {
  background-color: #f2f2f2;
  font-weight: bold;
  font-size: 11px;
}

.listnoh {
  font-size: 9px;
}

.col-lg-6 {
  flex: 0 0 auto;
  width: 10%;
}
/* .sortable{
  background-color: grey;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
}


.table{
  width: 100%;
  border-collapse: collapse;
}

th, td{
  border: 2px solid #ddd;
  padding: 5px;
  text-align: center;
}

th{
  background-color: #f2f2f2;
  font-weight: bold;
  font-size: 11px;
}

td{
  font-size: 9px;
} */

.col-md-6 .col-xs-6 .col-sm-6 .col-lg-6 {
  background-color: #3498db;
  visibility: hidden;
}

.hapus {
  cursor: pointer;
}

.cari {
  margin-bottom: 2%;
  margin-top: 2%;
}

.excel {
  /* background-color: white; */
  margin-top: 2%;
  margin-bottom: 1%;
  font-weight: bold;
 
}

.export-excel {
  background-color: gray;
  /* justify-content: left; */
  /* width: 10%; */
  border: none;
  /* color: black; */
  border-radius: 3px;
  font-size: 15px;
  padding: 10px;
  font-weight: bold;
  cursor: pointer;
}

.pagi {
  margin-top: 1.5%;
  display: inline-block;
}

.pagi a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
  cursor: pointer;
}

img {
  cursor: pointer;
}

.gambarPembayaran:hover {
  width: 55px;
}

.pagi a.active {
  background-color: #4caf50;
  color: white;
  border-radius: 5px;
}

.pagi a:hover:not(.active) {
  background-color: #ddd;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading::before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  border: 2px solid #3498db;
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.loading-widget {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh;
  padding: 10px;
}

.loading-widget::before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  border: 2px solid #3498db;
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 600px) {
  table {
    display: block;
    width: 100%;
    overflow-x: scroll;
  }

  .listnoh {
    width: 150px;
  }

  .listnih {
    width: 150px;
  }

  .excel {
    background-color: white;
    margin-top: 2%;
    margin-bottom: 1%;
    font-family: "Poppins", sans-serif;
  }

  .export-excel {
    background-color: gray;
    /* justify-content: left; */
    /* width: 30%; */
    font-size: 10px;
    padding: -10px;
  }
}

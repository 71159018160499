.background {
  background-image: url(../img/pemkot.jpg);
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginContainer {
  background-color: white;
  padding: 50px;
  border-radius: 6px;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
}

.kosong {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* padding: 10px; */
}

.kosong>h4{
  text-align: center;
}

.input {
  padding: 15px;
  margin: 10px 0;
  border-radius: 5px;
  border: none;
  background-color: lightgray;
}

.nihLogin{
  width: 250px;
}

.button {
  width: 100%;
  padding: 15px;
  background-color: #ad241b;
  color: white;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  margin-top: 10px;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-size: 10px;
}

.button::before{
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #fff;
  border-radius: 4px;
  scale: 0 1;
  transform-origin: right;
  transition: scale 0.25s;
}

.button:hover::before {
  scale: 1;
  transform-origin: left;
}

.button:hover{
  color: lightgray;
  background-color: #cd241b;
}

.input::placeholder {
  color: gray;
  font-style: italic;
}